<!--商户销售明细页-->
<template>
  <!-- 路径 -->
  <div class="path">
    <span><router-link to="/"> 首页</router-link></span> 
    &nbsp; &gt; &nbsp;
    <span>商户供货报表</span>
  </div>
  <div class="card-box">
    <div class="flex-start mb_15">
      <div class="news_title1">商户供货明细表</div>
      <div class="news_title2">&nbsp;</div>
    </div>
    
    <div class="search-div">  
      商户名称：
      <el-input placeholder="请输入商户名称" prefix-icon="search" v-model="shopName" clearable class="width"></el-input>            
      时间区间：
      <el-date-picker
        v-model="dateValue"
        type="daterange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间" 
        format="YYYY年MM月DD日"
        value-format="YYYY-MM-DD"             
      />
      经营品类：
      <el-input placeholder="请输入经营商品类别" prefix-icon="search" v-model="businessFormatName" clearable class="width"></el-input>
      <el-button type="primary" icon="search" @click="getSummaryData">查询</el-button>
      <el-button type="primary" icon="Download" @click="getExportData">导出</el-button>
    </div>
    <el-table v-loading="tableLoading" :data="listData" highlight-current-row stripe border
        style="width: 100%" header-row-class-name="header-row" :row-class-name="tableRowClassName">
      <!-- <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column type="index" label="序" width="43"></el-table-column> -->
      <el-table-column prop="shopName" label="商户名称" show-overflow-tooltip min-width="150" />
      <el-table-column prop="boothNo" label="摊位号" min-width="80" />
      <el-table-column prop="businessFormatName" label="品类" min-width="80" />
      <el-table-column prop="saleDate" label="供货时间" min-width="160" />
      <el-table-column prop="saleAmount" label="供货金额" min-width="110" align="right" />            
    </el-table>
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="currentPage" :page-sizes="[10, 15, 20, 30, 40]"
        :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total='tableTotal'>
      </el-pagination>
    </div>        
  </div>
</template>

<script>
import {getFirstDayOfCurrentMonth, getLastDayOfCurrentMonth, formatDate} from '@/utils/datetime_utils.js'
import {formatCurrency} from '@/utils/chineseNum.js'
export default {
  data () {
    return {
      tableLoading: false,
      currentPage: 1,
      pagesize: 20,
      tableTotal: 0,
      saleTotalAmount: 0, // 销售金额合计
      // saleTotalCount: 0, //销售笔数合计
      listData: [],
      shopName: '', // 商户名称
      businessFormatName: '', // 商户经营类别
      dateValue: [],
      startDate: '', // 交易开始日期
      endDate: '' // 交易结束日期
    }
  },
  mounted () {
    // 当前月第一天和最后一天
    this.dateValue[0] = getFirstDayOfCurrentMonth()
    this.dateValue[1] = getLastDayOfCurrentMonth()
    this.getSummaryData()
  },
  methods: {
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange (size) {
      this.pagesize = size
      this.getListData()
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getListData()
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex === 0) {
        return 'Summary-row'
      }
      return ''
    },
    getSummaries() {
      const sums = {
        shopName: '合计',
        saleAmount: this.saleTotalAmount
      }
      this.listData.unshift(sums)      
    },
    // 商户销售总计
    async getSummaryData () {
      var en = {
        position: 'merchantTrade_querySaleSummary',
        paramMap: {
          orgId: '9ce5a4d757f9d6434008b1449d20a641',
          businessFormatName: this.businessFormatName,
          shopName: this.shopName,
          startDate: this.dateValue[0],
          endDate: this.dateValue[1]
        }
      }
      const { data: res } = await this.$http.post(this.$api.querySql, en) 
      // console.log('Summary', res)
      if (res.state.code === 10000) {
        this.saleTotalAmount = '¥'+ formatCurrency(res.body[0].saleTotalAmount, 2)
        this.getListData()
      } else {
        this.$message.error(res.state.message)
      }
    },
    // 商户销售明细
    async getListData () {
      this.tableLoading = true
      var en = {
        position: 'merchantTrade_querySaleDetail',
        paramMap: {
          orgId: '9ce5a4d757f9d6434008b1449d20a641',
          businessFormatName: this.businessFormatName,
          shopName: this.shopName,
          startDate: this.dateValue[0],
          endDate: this.dateValue[1]
        },
        pageable: {
            pageNumber: this.currentPage,
            pageSize: this.pagesize
        }
      }
      const { data: res } = await this.$http.post(this.$api.querySqlByPage, en) 
      // console.log('Detail', res)
      if (res.state.code === 10000) {
        this.tableTotal = res.body.totalCount
        this.listData = res.body.list
        this.listData.forEach(p => {
          p.saleAmount = '¥'+ formatCurrency(p.saleAmount, 2)
        })
        this.getSummaries()
      } else {
        this.$message.error(res.state.message)
      }
      this.tableLoading = false
    },
    async getExportData () {
      var dt = this.dateValue[0] === this.dateValue[1] ? formatDate(this.dateValue[0], 'yyyy-MM-dd') : formatDate(this.dateValue[0], 'yyyy-MM-dd') + '至' + formatDate(this.dateValue[1], 'yyyy-MM-dd')
      var exportFileName = `商户供货明细表${dt}`
      var en = {
        "sheetDatas":
          {
            "fields": [
              "shopName",
              "boothNo",
              "businessFormatName",
              "saleDate",
              "saleAmount"
            ],
            "titles": [
              "商户名称",
              "摊位号",
              "品类",
              "供货时间",
              "供货金额"
            ],
            "sheetName": '商户供货明细表'
          },        
        "param": {
            "position": "merchantTrade_querySaleDetail",
            "paramMap": {
              "orgId": "9ce5a4d757f9d6434008b1449d20a641",
              "businessFormatName": this.businessFormatName,
              "shopName": this.shopName,
              "startDate": this.dateValue[0],
              "endDate": this.dateValue[1]
            }
        },
        "exportFileName": exportFileName
      }
      const res = await this.$http.postdown(this.$api.queryExport, en)     
      // console.log('Export', res)
      if (res.status === 200) {
        this.$fileDownload(res.data, `${exportFileName}.xlsx`)
      } else {
        this.$message.error(res.statusText)
      }
    }
  }
}
</script>

<style>
</style>
